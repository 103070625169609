<template>
  <BaseElFormItem label="啟用通知">
    <BaseElSwitch
      v-model="syncValue"
      active-text="開啟"
      inactive-text="關閉"
    />
  </BaseElFormItem>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
export default defineComponent({
  name: 'EnabledNotification',
  props: { value: Boolean },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    return { syncValue }
  },
})
</script>

<style lang="postcss" scoped>

</style>
