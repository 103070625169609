<template>
  <div class="LySlider">
    <div class="lyInner" style="margin-bottom: 24px;">
      <div class="lyItem LyMe">
        <div class="T1 fxLTR" dir="ltr">
          <div class="t1Body">
            <div class="MdBx vr" style="color: #3B3324; font-size: 15px;" v-html="content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'PreviewTextBlock',
  props: {
    text: { type: String, default: '' },
  },
  setup (props) {
    const content = computed(() => {
      return props.text.replace(/\n/g, '<br>')
    })
    return {
      content,
    }
  },
})
</script>

<style lang="postcss" scoped>
.info {
  @apply text-sm text-white mt-[12px] mb-[20px]
}
.info ul {
  @apply pl-[16px] list-disc
}
</style>
