<template>
  <BaseElFormItem label="接收通知附加條件">
    <BaseElSelect v-model="syncValue">
      <BaseElSelectOption
        v-for="option in targetConditionTypeConfigs"
        :key="option.value"
        :value="option.value"
        :label="option.label"
      />
    </BaseElSelect>
  </BaseElFormItem>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
import { targetConditionTypeConfigs } from '@/config/progressNotification'

export default defineComponent({
  name: 'NotificationAttachConditionBlock',
  props: {
    value: String,
  },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    return { syncValue, targetConditionTypeConfigs }
  },
})
</script>

<style lang="postcss" scoped>

</style>
