<template>
  <BaseElFormItem label="發送渠道" :prop="`NotifySettings[${notifyIndex}].channels`">
    <BaseElCheckboxGroup v-model="syncValue">
      <BaseElCheckbox label="LINE">Line</BaseElCheckbox>
      <!-- <BaseElCheckbox label="email">Email</BaseElCheckbox>
      <BaseElCheckbox label="sms">簡訊</BaseElCheckbox> -->
    </BaseElCheckboxGroup>
  </BaseElFormItem>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
export default defineComponent({
  name: 'SendChannelOptionsBlock',
  props: {
    notifyIndex: Number,
    value: { type: Array, defualt: () => [] },
  },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    return { syncValue }
  },
})
</script>

<style lang="postcss" scoped>

</style>
