<template>
  <div class="title-with-switch">
    <slot name="title">
      <p class="title">
        {{ title }}
      </p>
    </slot>
    <BaseElSwitch
      v-model="syncToggle"
      :inactive-text="inactiveText"
      :active-text="activeText"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'TitleWithSwitch',
  props: {
    title: String,
    bold: { type: Boolean, default: true },
    toggle: Boolean,
    inactiveText: String,
    activeText: String,
  },
  setup (props, { emit }) {
    const syncToggle = useVModel(props, 'toggle', emit)
    return { syncToggle }
  },
})
</script>

<style lang="postcss" scoped>
.title-with-switch {
    @apply flex justify-between mb-[8px];
}

.title-with-switch .title {
    @apply text-normal leading-[23.17px] text-base font-medium;
}

.isBold {
    @apply font-medium;
}

::v-deep .el-switch .el-switch__label {
    @apply text-gray-60 ;
}
</style>
