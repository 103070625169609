<template>
  <GrayBlockContainer>
    <div>
      <TitleWithSwitch :toggle.sync="syncToggle" :inactiveText="inactiveText">
        <template #title>
          <div class="flex">
            <p class="text-primary-100 font-bold text-normal" style="line-height: 23.17px;">{{ title }}</p>
            <TipInfo v-if="titleTooltip" width="200">
              {{ titleTooltip }}
            </TipInfo>
          </div>
        </template>
      </TitleWithSwitch>

      <div v-if="!hideForm" class="flex items-center">
        <p>套用通知內容範本</p>
        <TipInfo v-if="!hideTooltip" width="200">
          <slot name="tooltip" />
        </TipInfo>
      </div>

      <BaseElFormItem v-if="!hideForm" :prop="`NotifySettings[${notifyIndex}].contents[${contentIndex}].NotifyContentTemplateId`">
        <ElInputWrapper>
          <BaseElSelect
            v-model="syncTemplate"
            style="width: 100%"
            :disabled="!syncToggle"
          >
            <BaseElSelectOption
              v-for="option in contentTemplateOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </BaseElSelect>
        </ElInputWrapper>
      </BaseElFormItem>
    </div>
  </GrayBlockContainer>
</template>

<script>
import { defineComponent, ref } from 'vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import TitleWithSwitch from '@/components/TitleWithSwitch.vue'
import TipInfo from '@/components/TipInfo.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'ToggleNotificationContentBlock',
  components: { GrayBlockContainer, TitleWithSwitch, TipInfo, ElInputWrapper },
  props: {
    title: String,
    formItemProp: String,
    inactiveText: String,
    notifyIndex: Number,
    contentIndex: Number,
    enabled: Boolean,
    contentTemplate: String,
    contentTemplateOptions: { type: Array, default: () => [] },
    hideTooltip: Boolean,
    hideForm: {
      type: Boolean,
      default: false,
    },
    titleTooltip: {
      type: String,
      default: null,
    },
  },
  setup (props, { emit }) {
    const test = ref(null)
    const syncToggle = useVModel(props, 'enabled', emit)
    const syncTemplate = useVModel(props, 'contentTemplate', emit)

    return { test, syncToggle, syncTemplate }
  },
})
</script>

<style lang="postcss" scoped>

</style>
