<template>
  <div class="LySlider">
    <div class="lyInner" style="margin-bottom: 24px;">
      <div class="lyItem LyMe">
        <div class="T1 fxLTR" dir="ltr" style="background: rgba(255,255,255, 40%);">
          <div class="t1Body" style="display: flex; align-items: center;padding: 44px 0;">
            <div class="MdBx vr" style="color: #ffffff; font-size: 16px;padding: 4.5px 12px;background: rgba(0, 0, 0, 0.5);border-radius: 8px;">
              不顯示內容
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PreviewHiddenBlock',
  setup (props) {
    return {
    }
  },
})
</script>
